<template>
  <CardWrapper
    v-bind="$attrs"
    min-height="auto"
    data-test="resource-card"
  >
    <VRow>
      <VCol cols="auto">
        <VAvatar
          size="40"
          rounded="lg"
          :color="icon.backgroundColor"
        >
          <VIcon
            :color="icon.iconColor"
            size="24"
            data-test="resource-card-icon"
          >
            {{ icon.name }}
          </VIcon>
        </VAvatar>
      </VCol>
      <VCol>
        <slot>
          <div
            class="tt-text-body-1 tt-light-mono-100--text"
            data-test="resource-card-title"
          >
            {{ title }}
          </div>
          <div
            class="tt-text-body-2 tt-light-mono-64--text"
            data-test="resource-card-description"
          >
            {{ description }}
          </div>
        </slot>
      </VCol>
    </VRow>
  </CardWrapper>
</template>
<script>
import CardWrapper from '@/components/shared/CardWrapper.vue';

export default {
  name: 'ResourceCard',
  components: { CardWrapper },
  props: {
    cardType: {
      type: String,
      default: 'attachment',
      validator: (value) => ['video', 'attachment'].includes(value),
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    icon() {
      switch (this.cardType) {
        case 'video': return {
          backgroundColor: 'tt-light-red pale',
          iconColor: 'tt-light-red base',
          name: 'fal fa-play',
        };
        case 'attachment':
        default: return {
          backgroundColor: 'tt-light-blue pale',
          iconColor: 'tt-light-blue base',
          name: 'fal fa-link',
        };
      }
    },
  },
};
</script>
