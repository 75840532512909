<template>
  <TTChip
    v-if="date"
    v-bind="$attrs"
    :outlined="false"
    small
    color="tt-light-blue pale"
    text-color="tt-light-blue base"
    data-test-label="chip-date"
  >
    <VIcon
      size="14"
      left
      data-test="chip-date-icon"
    >
      fa fa-calendar-alt
    </VIcon>
    <span
      class="tt-text-caption"
      data-test="chip-date-text"
    >
      {{ formattedDate }}
    </span>
  </TTChip>
</template>

<script>
export default {
  name: 'ChipDate',
  props: {
    date: {
      type: [String, Object],
      default() {
        return this.$dayjs();
      },
    },
  },
  computed: {
    formattedDate() {
      return this.$dayjs(this.date).format('LL');
    },
  },
};
</script>
