<template>
  <VContainer>
    <VRow>
      <VCol>
        <h1
          class="tt-text-headline-1"
          data-test="about-title"
        >
          О продукте
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h2
          class="tt-text-title-1"
          data-test="learning-subtitle"
        >
          Обучающие материалы
        </h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-for="(resource,index) in resources"
        :key="index"
        cols="8"
      >
        <ResourceCard
          :title="resource.title"
          :description="resource.description"
          :href="resource.link"
          target="_blank"
          :data-test-label="resource.dataTestLabel"
          :card-type="resource.type"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h2
          class="tt-text-title-1"
          data-test="change-history-title"
        >
          История изменений
        </h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-for="(change,index) in changelog"
        :key="index"
        cols="8"
      >
        <HistoryCard
          :title="change.title"
          :description="change.text"
          :date="change.pub_date"
          :subtitle="change.sub_title"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import ResourceCard from '@/components/cards/ResourceCard.vue';
import HistoryCard from '@/components/cards/HistoryCard.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'About',
  components: {
    HistoryCard,
    ResourceCard,
  },
  data() {
    return {
      resources: [
        {
          title: 'Инструкция по работе в личном кабинете',
          description: 'Информация обо всех настройках приложения «Снами» и важных моментах,'
            + ' на которые стоит обратить внимание.',
          link: 'https://docs.google.com/document/d/1g2HT5iM4qsZlBnp2LiuTGl606T2UdzLR/edit',
          type: 'attachment',
          dataTestLabel: 'manual',
        },
        {
          title: 'Чек-лист для проверки при самостоятельном запуске трека',
          description: 'Убедитесь, что вы всё настроили и не пропустили шаги.',
          link: 'https://drive.google.com/file/d/1AvqxxyKBsMLnN4S-lYgQTlMjpgjgZb8U/view?usp=sharing',
          type: 'attachment',
          dataTestLabel: 'checklist',
        },
        {
          title: 'Видеоинструкция по работе в личном кабинете',
          description: 'Информация обо всех настройках приложения «Снами» в формате видео.',
          link: 'https://www.youtube.com/playlist?list=PLhdrGaRZ3KcrveWrKBOhQMY2YJzS3zwxG',
          type: 'video',
          dataTestLabel: 'video',
        },
      ],
    };
  },
  computed: {
    ...mapState('changelog', ['changelog']),
  },
  created() {
    if (!this.changelog.length) {
      this.getChangelog();
    }
    this.updateReadDate();
  },
  methods: {
    ...mapActions('changelog', ['getChangelog', 'updateReadDate']),
  },
};
</script>
