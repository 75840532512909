<template>
  <CardWrapper
    class="pa-0"
    shadow="never"
    data-test="history-card"
    min-height="auto"
  >
    <VRow
      v-if="date"
      no-gutters
      class="pb-2"
      justify="space-between"
    >
      <VCol cols="auto">
        <ChipDate :date="date" />
      </VCol>
      <VCol cols="auto">
        <MenuWrapper
          v-if="menu"
          :menu-list="getActionsList"
        >
          <template #activator="{ on }">
            <TTBtn
              fab
              small
              depressed
              color="transparent"
              class="table-menu-button"
              data-test="history-card-menu"
              v-on="on"
            >
              <VIcon size="19">
                fal fa-ellipsis-h
              </VIcon>
            </TTBtn>
          </template>
        </MenuWrapper>
      </VCol>
    </VRow>
    <VRow
      v-if="title"
      dense
    >
      <VCol
        class="tt-text-title-2"
        data-test="history-card-title"
      >
        {{ title }}
      </VCol>
    </VRow>

    <VRow
      v-if="subtitle"
      dense
    >
      <VCol
        class="tt-text-body-1 tt-light-mono-46--text"
        data-test="history-card-subtitle"
      >
        {{ subtitle }}
      </VCol>
    </VRow>
    <VRow
      v-if="description"
      dense
    >
      <VCol
        class="tt-text-body-1 history-card-description"
        data-test="history-card-description"
        v-html="description"
      />
    </VRow>
    <VRow v-if="image">
      <VCol>
        <CardWrapper>
          <VResponsive :aspect-ratio="16/9">
            <VImg
              :src="image"
              data-test="history-card-image"
            />
          </VResponsive>
        </CardWrapper>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/shared/CardWrapper.vue';
import ChipDate from '@/components/chips/ChipDate.vue';
import MenuWrapper from '@/components/shared/MenuWrapper.vue';

export default {
  name: 'HistoryCard',
  components: {
    ChipDate,
    CardWrapper,
    MenuWrapper,
  },
  props: {
    date: {
      type: [Object, String],
      default: () => null,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    description: {
      type: String,
      default: () => null,
    },
    image: {
      type: String,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getActionsList() {
      return [
        {
          icon: 'fal fa-pen',
          color: '',
          text: 'Редактировать',
          dataTest: 'history-card-edit',
          action: () => this.$emit('toggleEdit'),
        }, {
          icon: 'fal fa-trash-alt',
          color: 'tt-light-red',
          text: 'Удалить',
          dataTest: 'history-card-delete',
          action: () => this.$emit('toggleDelete'),
        },
      ];
    },
  },
};
</script>

<style>
.history-card-description img {
  max-width: 100%;
}
</style>
